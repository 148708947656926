export const PAGE_TYPE = Object.freeze({
  HOME_PAGE: "home-page",
  SECTION_PAGE: "section-page",
  TAG_PAGE: "tag-page",
  SEARCH_PAGE: "search-page",
  STORY_PAGE: "story-page",
  AUTHOR_PAGE: "author-page",
  CATALOG_PAGE: "catalog-page",
  STORY_PUBLIC_PREVIEW_PAGE: "story-public-preview-page",
  STORY_PREVIEW: "story-preview",
  HOME_PREVIEW: "home-preview",
  FORM_PAGE: "form-page",
  STATIC_PAGE: "static-page",
  VISUAL_STORY: "visual-story",
  TULO_LOGIN: "tulo-login",
  NATIVE_AD_CARD: "native-ad-card"
});

export const STORY_FIELDS =
  "headline,subheadline,summary,url,sections,tags,author-name,author-id,authors,updated-at,last-published-at,published-at,updated-at,first-published-at,metadata,hero-image-metadata,hero-image-s3-key,story-content-id,slug,id,seo,story-template";
export const TAG_PAGE_URL_PREFIX = "/topic/";
