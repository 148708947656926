import React from "react";
import { connect } from "react-redux";
import { array } from "prop-types";
import get from "lodash/get";
import { Link } from "@quintype/components";
import FooterSocialMediaIcons from "../../layouts/footer/footer-social-media-icon";
import "./footer.m.css";
import { FooterAppLogo } from "../../layouts/footer/footer-app-logo";
import { NewspaperLogo } from "../../layouts/footer/newspaper-logo";
import { GooglePlayBadge } from "../../atoms/google-play-badge";
import { AppStoreBadge } from "../../atoms/app-store-badge";
import useUrl from "../../../hook/useUrl";

const StaticFooterLinks = ({ staticFooterLinks }) => {
  return (
    <ul styleName="static-link">
      {staticFooterLinks.length >= 1 &&
        staticFooterLinks.map(item => {
          return item.isExternalLink ? (
            <li styleName="static-link-list" key={item.title}>
              <a href={item.completeUrl} styleName="static-link-item" target="_blank" rel="noreferrer">
                {item.title}
              </a>
            </li>
          ) : (
            <li styleName="static-link-list" key={item.title} target="_blank">
              <Link href={item.completeUrl || "/"} styleName="static-link-item">
                {item.title}
              </Link>
            </li>
          );
        })}
        {/*Evästeasetusplaceholder, toiminnallisuus gtm:stä*/}
        <li styleName="static-link-list" key="evästeasetukset">
          <a styleName="static-link-item evasteasetukset" className="evasteasetukset">
            Evästeasetukset
          </a>
        </li>
    </ul>
  );
};

const ContactInfo = () => {
  return (
    <React.Fragment>
      {
        <div styleName="footer-info">
          <div styleName="first-info">
            <p styleName="footer-contact-heading">Katuosoite</p>
            Rantakatu 38, Kristiinankaupunki
          </div>
          <div styleName="second-info">
            <p styleName="footer-contact-heading">Puhelin</p>
            06 2477 281
          </div>
          <div styleName="third-info">
            <a href="https://asiakaspalvelu.i-mediat.fi/suupohjansanomat/ota-yhteytta/" target="_blank" styleName="opening-hours">Katso aukioloajat</a>
          </div>
          <div styleName="fourth-info">
            <div styleName="logo">
              <FooterAppLogo />
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
};

const FooterMenuLinks = ({ footerMenuLinks }) => {
  return (
    <React.Fragment>
      {footerMenuLinks.length >= 1 && (
        <div styleName="menu-link">
          {footerMenuLinks.map(item => {
            return (
              <ul key={item.title} styleName="footer-link-column">
                <li key={item.title} styleName="footer-item">
                  {item.title}
                  <br />
                </li>
                {item.children.map(child => (

                    <li key={child.title} styleName="footer-child-item">
                      {(child.isExternalLink && !((child.completeUrl.includes("suupohjansanomat.fi") ||
                            (child.completeUrl.includes("localhost")) ||
                            (child.completeUrl.includes("qtstage")))) || (child.completeUrl.includes("lounaspaikka")) === true) ? (
                        <a styleName="link" rel="noopener noreferrer" href={child.completeUrl} target="_blank">
                          {child.title}
                        </a>
                      ) : (
                        <a styleName="link" rel="noopener noreferrer" href={child.completeUrl}>
                          {child.title}
                        </a>
                      )}
                    </li>

                ))}
              </ul>
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
};

const FooterBase = props => {
  const [toHideNative] = useUrl();
  return (
    <div styleName={!toHideNative ? "wrapper" : "hide"}>
      <div styleName="inner-wrapper">
        <div styleName="newspaper-logo">
          <NewspaperLogo />
        </div>
        <div styleName="footer-line"></div>
        <div styleName="footer-grid">
          <div styleName="top-row">
            <div styleName="left-column">
              <ContactInfo />
            </div>
            <div styleName="center-column">
              <div styleName="up-button-mobile-div">
                <button
                  onClick={() =>
                    window.scroll({
                      top: 0,
                      left: 0,
                      behavior: "smooth"
                    })
                  }
                  styleName="up-button-mobile"
                >
                  <div styleName="up-button-text">
                    TAKAISIN YLÖS <i className="ri-arrow-up-line"></i>
                  </div>
                </button>
              </div>
              <br />
              <FooterMenuLinks footerMenuLinks={props.footerMenuLinks} />
            </div>
            <div styleName="right-column">
              <div>
                <button
                  onClick={() =>
                    window.scroll({
                      top: 0,
                      left: 0,
                      behavior: "smooth"
                    })
                  }
                  styleName="up-button"
                >
                  <div styleName="up-button-text">
                    TAKAISIN YLÖS <i className="ri-arrow-up-line"></i>
                  </div>
                </button>
              </div>
              <div styleName="footer-social-media">
                <FooterSocialMediaIcons />
              </div>
              {/*<div styleName="app-badges">
                <GooglePlayBadge />
                <AppStoreBadge />
                </div>*/}
            </div>
          </div>
        </div>
      </div>
      <div styleName="footer-bottom-links">
        <StaticFooterLinks staticFooterLinks={props.staticFooterLinks} />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    staticFooterLinks: get(state, ["qt", "data", "navigationMenu", "footerStaticMenuLinks"], []),
    footerMenuLinks: get(state, ["qt", "data", "navigationMenu", "footerMenuLinks"], [])
  };
};

StaticFooterLinks.propTypes = {
  staticFooterLinks: array
};

FooterMenuLinks.propTypes = {
  footerMenuLinks: array
};

FooterBase.propTypes = {
  staticFooterLinks: array,
  footerMenuLinks: array
};

const Footer = connect(mapStateToProps)(FooterBase);

export { Footer };
