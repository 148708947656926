import React from "react";
import { Link } from "@quintype/components";
import ImediatLogo from "../../../basic/images/ilkka_logo_white_h25.png";
import "./footer-app-logo.m.css";
import assetify from "@quintype/framework/assetify";

const FooterAppLogo = () => (
  <Link href="https://ilkka.com/" target="_blank" styleName="publisher-logo">
    <img
      src={`${assetify(ImediatLogo)}`}
      alt="Ilkka.com"
      data-retina_logo_url=""
    />
  </Link>
);

export { FooterAppLogo };
