import React from "react";
import { Link } from "@quintype/components";
import IpLogo from "../../../basic/images/SuupohjanSanomat_logo_valkoinen.png";
import "./newspaper-logo.m.css";
import assetify from "@quintype/framework/assetify";

const NewspaperLogo = () => (
  <Link href="/" styleName="newspaper-logo">
    <img src={`${assetify(IpLogo)}`}
    alt="Logo"
    data-retina_logo_url=""
     />
  </Link>
);

export { NewspaperLogo };

