import { renderIsomorphicComponent, renderComponent, renderBreakingNews } from "@quintype/framework/client/start";
import { pickComponent } from "../isomorphic/pick-component";
import { BreakingNewsLocalView } from "../isomorphic/components/breaking-news-local-view";
import { Header } from "../isomorphic/components/header";
import { Footer } from "../isomorphic/components/layouts/footer";
import LoadingIndicatorComponent from "../isomorphic/components/atoms/loading-indicator";
import { get } from "lodash";

export function preRenderApplication(store) {
  //const breakingNewsbaseProps = {
  //  hydrate: !global.qtLoadedFromShell,
  //  updateInterval: 10000
  //};
  const hydrate = { hydrate: !global.qtLoadedFromShell };
  const pageType = get(store.getState(), ["qt", "pageType"])
  renderComponent(Header, "header", store, hydrate);
  (pageType == "home-page" || pageType == "section-page" || pageType == "story-page") && renderComponent(BreakingNewsLocalView, "breaking-news-imitator-container", store, hydrate);
  renderComponent(LoadingIndicatorComponent, "loading-indicator", store);
  renderComponent(Footer, "footer", store, hydrate);
}

// This is a separate file as everything from here on is hot reloaded when the app changes
export function renderApplication(store) {
  renderIsomorphicComponent("container", store, pickComponent, {
    hydrate: !global.qtLoadedFromShell
  });
}
