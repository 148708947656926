import React, { useEffect, useState } from "react";
import DOMPurify from 'dompurify';
import PT from "prop-types";
import "./login.m.css";
import useLinks from "../../../hook/useLinks";

const LoginBase = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [links] = useLinks("forgotpassword");
  const handleEmailChange = (e) => {
    const { value } = e.target
    const emailValue = DOMPurify.sanitize(value);
    setEmail(emailValue);

  }
  const handlePasswordChange = (e) => {
    const { value } = e.target
    const passwordValue = DOMPurify.sanitize(value);
    setPassword(passwordValue);
  }

  // Initialize a boolean state
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordIcon, setPasswordIcon] = useState(true);
  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
    setPasswordIcon(!passwordIcon);
  };
  return (
    <React.Fragment>
      <div id="loginBox" styleName="qt-login-form" style={props.formStyle} className="qt-login-forms">
        <div styleName="login-desktop">
          <form action="/tulo/authenticate" method="post">
            <div className="input-group">
              <div className="input-field">
                <label>Käyttäjätunnus</label>
                <input
                  value={email}
                  type="email"
                  name="email"
                  styleName="header-login"
                  required
                  onChange={(e) => handleEmailChange(e)}
                />
              </div>
              <div className="input-field">
                <label>Salasana</label>
                <div styleName="passWd">
                  <input
                    value={password}
                    styleName="header-login-passWd"
                    //type="password"
                    type={passwordShown ? "text" : "password"}
                    name="password"
                    required
                    onChange={(e) => handlePasswordChange(e)}
                  />
                  <a aria-label="Näytä salasana" styleName="show-passWd" role="button" onClick={togglePassword}><i className={`${passwordIcon ? "ri-eye-line" : "ri-eye-off-line"}`}></i></a>
                </div>
              </div>
            </div>
            <a href={links} styleName="forgot-password">Unohtuiko salasana?</a>
            <span className="input-group-btn">
              <button type="submit" styleName="login-submit">
                Kirjaudu sisään
              </button>
            </span>

          </form>
        </div>
        <div styleName="close-icon" onClick={props.toggleLoginForm}>
          <span styleName="login-text">Kirjaudu sisään</span><i className="ri-close-line"></i>
        </div>
      </div>
      <button styleName="btn_login" onClick={props.toggleLoginForm} style={props.initialStyle}>
        <span styleName="login-text">Kirjaudu sisään </span><i className="ri-user-shared-2-line"></i>
      </button>
    </React.Fragment>
  );

}
export { LoginBase };

LoginBase.propTypes = {
  formStyle: PT.object,
  initialStyle: PT.object,
  loginStyle: PT.object,
  id: PT.string,
  toggleLoginForm: PT.func,
  innerRef: PT.object
};
