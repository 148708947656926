import React from "react";
import PT from "prop-types";

import "./nav-bar-toggle-btn.m.css";

export const NavBarToggleBtn = ({ onMenuToggle, isMegaMenuOpen, id }) => {
  return (
    <button onClick={onMenuToggle} styleName={`hamburger ${(isMegaMenuOpen && id === 0) ? "is-open" : ""}`}>
      {/*<i className={`hamburger ${(isMegaMenuOpen && id === 0) ? "ri-close-line" : "ri-menu-line"}`}></i>*/}
      <i className="hamburger ri-menu-line"></i>
    </button>
  );
};

NavBarToggleBtn.propTypes = {
  onMenuToggle: PT.func,
  isMegaMenuOpen: PT.bool
};

export default NavBarToggleBtn;
