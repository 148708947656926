import React from "react";
import { connect } from "react-redux";
import { object } from "prop-types";
import get from "lodash/get";
import assetify from "@quintype/framework/assetify";
import FacebookLogo from "../../../../components/basic/images/facebook_logo.png"
import "./footer-social-media-icon.m.css";

const FooterSocialMediaIcons = ({ footerSocialLinks = [] }) => {

  return (
    <ul styleName="wrapper">
            <li styleName="footer-share-item">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/SuupohjanSanomat"
                      aria-label="Seuraa somessa!"
                    >
                <img src={`${assetify(FacebookLogo)}`}
            alt="facebook"/>
              </a>
            </li>
    </ul>
  );
};

function mapStateToProps(state) {
  return {
    footerSocialLinks: get(state, ["qt", "config", "social-links"], [])
  };
}

FooterSocialMediaIcons.propTypes = {
  footerSocialLinks: object
};

export default connect(mapStateToProps, () => ({}))(FooterSocialMediaIcons);
